import {
  computed,
  onMounted,
  onUnmounted,
  readonly,
  ref,
  toRaw,
  type UnwrapRef,
} from 'vue'
import { EFetchState } from '@/domain/Book/contracts/EFetchState'
import { builderIoClientFactory } from '@/domain/Book/support/builderIoClientFactory'
import type { WretchError } from 'wretch'
import type { TRequestError } from '@/domain/Book/contracts/TRequestError'
import { toRequestErrors } from '@/domain/Book/support/toRequestErrors'
import { useCategories } from '@/domain/Book/composables/useCategories'
import { useAuthors } from '@/domain/Book/composables/useAuthors'
import type { TBookDirectoryEntry } from '@/domain/Book/contracts/TBookDirectoryEntry'
import { toIds } from '@/domain/Book/support/toIds'
import type { DeepReadonly } from '@vue/reactivity'
import type { TAuthor } from '@/domain/Book/contracts/TAuthor'
import type { TCategory } from '@/domain/Book/contracts/TCategory'
import { watchDebounced } from '@vueuse/core'
import { idsToString } from '@/domain/Book/support/idsToString'
import { useDomains } from '@/domain/domains/composables/useDomains'
import type { TDomain } from '@/domain/domains/contracts/TDomain'

export const MODEL = 'book-page'
export const MODEL_ID = '31f6043cc84947eea853ff0c8b80f0d6'

const _selectedCategoryIds = ref<string[]>([])
const _entries = ref<TBookDirectoryEntry[]>([])
const _fetchState = ref<EFetchState>(EFetchState.IDLE)
const _errors = ref<{ errors: TRequestError[] }>()
const _areCategoriesInitialized = ref<boolean>(false)

export const useDirectoryEntries = (
  options: {
    useAutoFetch?: boolean
    filterByEnvironment?: boolean
    currentEnvironment?: string
  } = {
    useAutoFetch: true,
    filterByEnvironment: true,
    currentEnvironment: undefined,
  },
) => {
  const client = builderIoClientFactory().query({
    fields: [
      'id',
      'modelId',
      'data.directory.title',
      'data.directory.image',
      'data.directory.caseCover',
      'data.url',
      'data.specs',
      'data.authors',
      'data.categories',
      'data.template',
      'data.domain',
    ].join(','),
  })

  const {
    fetch: fetchCategories,
    categories,
    errors: categoryErrors,
    fetchState: categoryFetchState,
  } = useCategories({ useAutoFetch: false })

  const {
    fetch: fetchAuthors,
    authors,
    errors: authorsErrors,
    fetchState: authorsFetchState,
  } = useAuthors({ useAutoFetch: false })

  const {
    fetch: fetchDomains,
    domains,
    errors: domainsErrors,
    fetchState: domainsFetchState,
  } = useDomains({ useAutoFetch: false })

  const _doFetch = async (only?: string[]) => {
    try {
      _fetchState.value = EFetchState.FETCHING

      const rawEntries = await client
        .query({ limit: 100 })
        .query(
          only
            ? {
                'query.id.$in': ['', ...only], // hint `''` is required when missing client does not use array syntax
              }
            : {},
        )
        .query(
          _selectedCategoryIds.value.length === 0
            ? {}
            : {
                'query.data.categories.$elemMatch': `{"category.id":{"$in":${idsToString(
                  _selectedCategoryIds.value,
                )}}}`,
              },
        )
        .query(
          options.filterByEnvironment && options.currentEnvironment
            ? {
                'query.data.environment.$eq': options.currentEnvironment.toLowerCase(),
              }
            : {},
        )
        .get(`/${MODEL}`)

      const entries = rawEntries.map(
        (item): TBookDirectoryEntry => ({
          id: item.id,
          type: item.type === MODEL_ID ? MODEL : MODEL_ID,
          attributes: {
            title: item?.attributes?.directory?.title,
            image: item?.attributes?.directory?.image,
            caseCover: item?.attributes?.directory?.caseCover,
            path: item.attributes.url,
            specs: item?.attributes?.specs ?? [],
            template: item?.attributes?.template,
          },
          relationships: {
            authors:
              item.attributes?.authors
                ?.map((modelAuthor) => ({
                  id: modelAuthor?.author?.id,
                  type: modelAuthor?.author?.model,
                }))
                .filter((modelAuthor) => modelAuthor?.id && modelAuthor?.type) ?? [],
            categories:
              item.attributes?.categories
                ?.map((modelCategory) => ({
                  id: modelCategory?.category?.id,
                  type: modelCategory?.category?.model,
                }))
                .filter((modelCategory) => modelCategory?.id && modelCategory?.type) ??
              [],
            domain: item.attributes?.domain?.id
              ? {
                  id: item.attributes?.domain?.id,
                  type: item.attributes?.domain?.type,
                }
              : undefined,
          },
        }),
      )

      const authorIds = toIds(
        entries
          .map((item: TBookDirectoryEntry) => item?.relationships?.authors ?? [])
          .flat(),
      )
      await fetchAuthors(authorIds)

      const domainIds = toIds(
        entries
          .map((item: TBookDirectoryEntry) => item?.relationships?.domain ?? undefined)
          .flat(),
      )

      await fetchDomains(domainIds)

      if (!_areCategoriesInitialized.value) {
        await fetchCategories()
        _areCategoriesInitialized.value = true
      }

      _entries.value = entries
      _fetchState.value = EFetchState.SUCCEEDED
    } catch (error: WretchError | unknown) {
      _fetchState.value = EFetchState.FAILED
      _errors.value = toRequestErrors(error as WretchError)
      // eslint-disable-next-line no-console
      console.error(toRaw(_errors.value))
    }
  }

  onMounted(async () => {
    if (options?.useAutoFetch) {
      await _doFetch()
    }
  })

  onUnmounted(() => {
    _fetchState.value = EFetchState.IDLE
    _entries.value = []
    _errors.value = undefined
    _selectedCategoryIds.value = []
    _areCategoriesInitialized.value = false
  })

  watchDebounced(
    _selectedCategoryIds,
    async (newIds, oldIds) => {
      if (newIds.length === 0 && oldIds.length === 0) {
        return
      }

      await _doFetch()
    },
    { debounce: 100, maxWait: 1000 },
  )

  return {
    fetchState: computed(() => {
      if (
        _fetchState.value === EFetchState.FAILED ||
        categoryFetchState.value === EFetchState.FAILED ||
        authorsFetchState.value === EFetchState.FAILED ||
        domainsFetchState.value === EFetchState.FAILED
      ) {
        return EFetchState.FAILED
      }

      if (
        _fetchState.value === EFetchState.IDLE &&
        categoryFetchState.value === EFetchState.IDLE &&
        authorsFetchState.value === EFetchState.IDLE &&
        domainsFetchState.value === EFetchState.IDLE
      ) {
        return EFetchState.IDLE
      }

      if (
        _fetchState.value === EFetchState.SUCCEEDED &&
        categoryFetchState.value === EFetchState.SUCCEEDED &&
        authorsFetchState.value === EFetchState.SUCCEEDED &&
        domainsFetchState.value === EFetchState.SUCCEEDED
      ) {
        return EFetchState.SUCCEEDED
      }

      return EFetchState.FETCHING
    }),
    errors: computed(() => {
      const resolvedErrors: { errors: TRequestError[] } = {
        errors: [] as TRequestError[],
      }

      const entryErrorsNonRef = _errors.value?.errors ?? []
      if (entryErrorsNonRef.length > 0) {
        resolvedErrors.errors = [...resolvedErrors.errors, ...entryErrorsNonRef]
      }

      const categoryErrorsNonRef = categoryErrors.value?.errors ?? []
      if (categoryErrorsNonRef.length > 0) {
        resolvedErrors.errors = [...resolvedErrors.errors, ...categoryErrorsNonRef]
      }

      const authorsErrorsNonRef = authorsErrors.value?.errors ?? []
      if (authorsErrorsNonRef.length > 0) {
        resolvedErrors.errors = [...resolvedErrors.errors, ...authorsErrorsNonRef]
      }

      const domainsErrorsNonRef = domainsErrors.value?.errors ?? []
      if (domainsErrorsNonRef.length > 0) {
        resolvedErrors.errors = [...resolvedErrors.errors, ...domainsErrorsNonRef]
      }

      return resolvedErrors.errors.length > 0 ? resolvedErrors.errors : undefined
    }),
    entries: readonly(_entries),
    authors: authors,
    categories: categories,
    domains: domains,
    selectedCategoryIds: _selectedCategoryIds,
    findAuthors: (entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>): TAuthor[] => {
      if (!entry) {
        return []
      }
      const ids = entry.relationships.authors.map((item) => item.id)

      if (!ids || ids.length === 0) {
        return []
      }

      return authors.value.filter((item) => ids.includes(item.id)) as TAuthor[]
    },
    findDomain: (
      entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>,
    ): TDomain | undefined => {
      if (!entry) {
        return undefined
      }

      return domains.value.find(
        (item) =>
          entry.relationships.domain?.id && item.id === entry.relationships.domain?.id,
      ) as TDomain | undefined
    },
    findCategories: (
      entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>,
    ): TCategory[] => {
      if (!entry) {
        return []
      }
      const ids = entry.relationships.categories.map((item) => item.id)

      if (!ids || ids.length === 0) {
        return []
      }

      return categories.value.filter((item) => ids.includes(item.id)) as TCategory[]
    },
    fetch: _doFetch,
  }
}
