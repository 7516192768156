import type { TCaseListReference } from '@/domain/domains/contracts/TCaseListReference'
import type { TCaseEntry } from '@/domain/domains/contracts/TCaseEntry'

export const toCaseEntry = (caseData: TCaseListReference): TCaseEntry | undefined => {
  return {
    id: caseData.case.id,
    type: caseData.case.model,
    attributes: {
      title: caseData.case.value.data.directory.title,
      path: caseData.case.value.data.url,
      image: caseData.case.value.data.directory.caseCover,
      description: caseData.case.value.data.directory.description,
      specs: caseData.case.value.data.specs ?? [],
      template: caseData.case.value.data.template,
    },
  }
}
