export enum CBuilderModels {
  Page = 'page',
  TextHero = 'text-hero',
  BookPage = 'book-page',
  DomainPage = 'domain-page',
  PromptInput = 'prompt-input',
  VideoSection = 'video-section',
  MainNavigationItems = 'main-navigation-items',
  DirectoryEntries = 'directory-entries',
  Experts = 'experts',
  FeedbackWidget = 'feedback-widget',
}
