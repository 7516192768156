import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/DomainHeroSection.vue'
import { CDomainModel } from '@/domain/domains/contracts/CDomainModel'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'
import { EDomainReferenceType } from '@/domain/domains/contracts/EDomainReferenceType'

export const DomainHeroSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'DomainHeroSection',
  friendlyName: 'DomainHeroSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      friendlyName: 'Use page content',
      name: 'usePageContent',
      type: 'boolean',
      helperText: 'Derive content from enclosing page instead of manually adding',
      defaultValue: false,
    },
    {
      friendlyName: 'Define page content model',
      name: 'pageContentModel',
      type: 'string',
      enum: ['page', CDomainModel.NAME],
      defaultValue: CDomainModel.NAME,
      showIf(options) {
        return options.get('usePageContent') === true
      },
    },
    {
      name: 'classes',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'container',
          type: 'text',
          defaultValue: 'mt-12 mb-6',
        },
        {
          name: 'contentContainer',
          type: 'text',
        },
        {
          name: 'titleContainer',
          type: 'text',
        },
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'subline',
          type: 'text',
        },
      ],
    },
    {
      name: 'title',
      type: 'longText',
      showIf(options) {
        return options.get('usePageContent') === false
      },
    },
    {
      name: 'name',
      type: 'text',
      showIf(options) {
        return options.get('usePageContent') === false
      },
    },
    {
      name: 'subline',
      type: 'longText',
      showIf(options) {
        return options.get('usePageContent') === false
      },
    },
    {
      name: 'image',
      friendlyName: 'Domain logo',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      showIf(options) {
        return options.get('usePageContent') === false
      },
    },
    {
      name: 'content',
      type: 'richText',
      showIf(options) {
        return options.get('usePageContent') === false
      },
    },
    {
      name: 'references',
      type: 'list',
      copyOnAdd: false,
      showIf(options) {
        return options.get('usePageContent') === false
      },
      subFields: [
        {
          name: 'type',
          type: 'string',
          enum: toKeyValue(EDomainReferenceType).filter((item) => item.label !== 'Email'),
        },
        {
          name: 'name',
          type: 'text',
        },
        {
          name: 'url',
          type: 'text',
        },
      ],
    },
  ],
})
