import { useUserStore } from '@/app/services/useUserStore'
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteRecordRaw,
  type RouteRecordRedirectOption,
} from 'vue-router'
import * as Fathom from 'fathom-client'

import { Environment } from '@/app/support/Environment'
import { blockListRoutes } from '@/domain/blockList/blockListRoutes'
import { authenticationRoutes, CAuthenticationRouteNames } from '@/domain/Authentication'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { shareTopicNavigationGuard } from '@/app/services/navigation-guards/shareTopicNavigationGuard'
import { routeLogger } from '@/app/services/routeLogger'
import { nextTick, type Ref, ref } from 'vue'
import { domainRoutes } from '@/domain/domains/domainRoutes'
import { directoryRoutes } from '@/domain/directory/directory-routes'
import { createDocument } from '@/domain/documents/services/documentClient'
import { addRoutes } from '@/app/support/addRoutes'
import { contentRoutes, PContent } from '@/domain/Content'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { EEnvironment } from '@/app/contracts/EEnvironment'
import { handleUnsupportedRouteEnvironmentGuard } from '@/app/guards/handleUnsupportedRouteEnvironmentGuard'
import type { TExcludedEnvironmentRouteMeta } from '@/app/contracts/TExcludedEnvironmentRouteMeta'
import { bookRoutes } from '@/domain/Book/bookRoutes'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import { blogRoutes } from '@/domain/Blog/blogRoutes'

export const router = createRouter({
  history: createWebHistory(),
  routes: [],
})

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: CRouteNames.home,
    component: PContent,
  },
  {
    path: '/dashboard',
    name: CRouteNames.dashboard,
    component: () => import('@/domain/dashboard/components/PDashboard.vue'),
    beforeEnter: async (to, from, next) => {
      if (!useUserStore().isLoggedIn.value) {
        captureWithUser(EUserEvents.receivedError, {
          route: to.name,
          route_query: { ...to.query },
          route_params: { ...to.params },
          guard: `${CRouteNames.dashboard}.beforeEnter`,
          error: `Authentication required to access '${CRouteNames.dashboard}' redirect to '${CAuthenticationRouteNames.signIn}'`,
          status: 401,
        })

        next({ name: CAuthenticationRouteNames.signIn })
        return
      }
      next()
    },
  },
  {
    path: '/items-branch-state',
    meta: {
      excludedEnvironments: [EEnvironment.PRODUCTION],
    },
    component: () => import('@/domain/ItemsBranchState/components/ItemsBranchState.vue'),
  },
  {
    path: '/topic/:topicId?',
    name: CRouteNames.topic,
    component: () => import('@/domain/cards/components/PTopic.vue'),
    beforeEnter: async (to, from, next) => {
      if (!useUserStore().isLoggedIn.value) {
        captureWithUser(EUserEvents.receivedError, {
          interaction_type: EDocumentInteractions.intendedToOpenADocument,
          route: to.name,
          route_query: { ...to.query },
          route_params: { ...to.params },
          guard: `${CRouteNames.topic}.beforeEnter`,
          error: `Authentication required to access '${CRouteNames.topic}' redirect to '${CAuthenticationRouteNames.signIn}'`,
          status: 401,
        })

        next({
          name: CAuthenticationRouteNames.signIn,
          query: {
            caseId: to.params.topicId,
          },
        })
        return
      }
      next()
    },
    children: [
      {
        path: 'c/:contextId',
        name: CRouteNames.topicWithContext,
        component: () => import('@/domain/cards/components/PTopic.vue'),
      },
    ],
  },
  {
    path: '/documents/create',
    name: CRouteNames.documents.create,
    beforeEnter: async (to, from, next) => {
      if (!useUserStore().isLoggedIn.value) {
        captureWithUser(EUserEvents.receivedError, {
          interaction_type: EDocumentInteractions.intendedToCreateADocument,
          route: to.name,
          route_query: { ...to.query },
          route_params: { ...to.params },
          guard: `${CRouteNames.documents.create}.beforeEnter`,
          error: `Authentication required to access '${CRouteNames.documents.create}' redirect to '${CAuthenticationRouteNames.signIn}'`,
          status: 401,
        })

        next({
          name: CAuthenticationRouteNames.signIn,
          query: to.query,
        })
        return
      }

      const prompt = to.query.prompt

      if (!prompt) {
        next({ name: CRouteNames.home, replace: true })
        return
      }

      const result = await createDocument(prompt as string)

      if (!result?.id) {
        // eslint-disable-next-line no-console
        console.error(`creating document failed for '${prompt}'`)
        next({ name: CRouteNames.home, replace: true })
        return
      }

      next({
        name: CRouteNames.topic,
        params: { topicId: result.id },
        query: to.query,
        replace: true,
      })
      return
    },
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
  {
    path: '/share-links/:shareId/apply',
    name: CRouteNames.shareTopic,
    beforeEnter: shareTopicNavigationGuard(router),
  } as RouteRecordRaw & { redirect: RouteRecordRedirectOption },
] satisfies RouteRecordRaw[]

addRoutes(routes, blockListRoutes)
addRoutes(routes, domainRoutes)
addRoutes(routes, directoryRoutes)
addRoutes(routes, authenticationRoutes)
addRoutes(routes, bookRoutes)
addRoutes(routes, blogRoutes)
addRoutes(routes, contentRoutes)

export { routes }

routes.forEach((route) => router.addRoute(route))

router.beforeEach(async (to, from) => {
  Fathom.trackPageview() // track all page visits with fathom

  const handleUnsupportedEnvironment = handleUnsupportedRouteEnvironmentGuard(
    to as RouteLocationNormalized & { meta?: TExcludedEnvironmentRouteMeta },
    Environment.current(),
    {
      name: CRouteNames.home,
      query: { ...to.query },
    } as RouteLocationNormalized,
  )

  if (handleUnsupportedEnvironment) {
    return handleUnsupportedEnvironment
  }

  if (to.matched.length === 0) {
    captureWithUser(EUserEvents.receivedError, {
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
      guard: 'router.beforeEach',
      comment: `Route not found redirect to '${CRouteNames.home}'`,
    })

    routeLogger({
      msg: `route not found & redirect to ${CRouteNames.home}`,
      nav: { from, to },
    })
    return { name: CRouteNames.home }
  }
})

const scrollInto = (
  toHash: string,
  scrollTrials: Ref<number> = ref(0),
  maxTrials = 3,
) => {
  if (scrollTrials.value > maxTrials) {
    // eslint-disable-next-line no-console
    console.log('❌ max scroll trials reached', {
      toHash,
      scrollTrials: scrollTrials.value,
      maxTrials,
    })
    return
  }

  const childElement = document.querySelector(toHash)

  if (childElement) {
    childElement.scrollIntoView({ behavior: 'smooth' })
    scrollTrials.value = 0
  } else {
    setTimeout(() => {
      scrollTrials.value = scrollTrials.value + 1
      scrollInto(toHash, scrollTrials)
    }, 250)
  }
}

router.afterEach(async (to, from) => {
  if (to.path !== from.path) {
    // @info ignore query parameters and rely solely on path changes
    captureWithUser(EUserEvents.visited, {
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
    })
  }

  nextTick(() => {
    if (!document) {
      return
    }

    if (!to.hash) {
      return
    }

    const scrollTrials = ref(0)
    scrollInto(to.hash, scrollTrials)
  })
})

export default router
