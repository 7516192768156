import { onMounted, onUnmounted, readonly, ref, toRaw } from 'vue'
import { EFetchState } from '@/domain/Book/contracts/EFetchState'
import { builderIoClientFactory } from '@/domain/Book/support/builderIoClientFactory'
import type { WretchError } from 'wretch'
import type { TRequestError } from '@/domain/Book/contracts/TRequestError'
import { toRequestErrors } from '@/domain/Book/support/toRequestErrors'
import type { TAuthor } from '@/domain/Book/contracts/TAuthor'
import { CDomainModel } from '@/domain/domains/contracts/CDomainModel'
import type { TDomain } from '@/domain/domains/contracts/TDomain'

export const MODEL = CDomainModel.NAME
export const MODEL_ID = CDomainModel.ID

const _domains = ref<TAuthor[]>([])
const _fetchState = ref<EFetchState>(EFetchState.IDLE)
const _errors = ref<{ errors: TRequestError[] }>()

export const useDomains = (options = { useAutoFetch: true }) => {
  const client = builderIoClientFactory().query({
    fields: [
      'id',
      'modelId',
      'data.title',
      'data.content',
      'data.image',
      'data.subline',
      'data.name',
      'data.url',
    ].join(','),
  })

  const _doFetch = async (only?: string[]) => {
    try {
      _fetchState.value = EFetchState.FETCHING

      _domains.value = (
        await client
          .query(
            only
              ? {
                  'query.id.$in': ['', ...only], // hint `''` is required when missing client does not use array syntax
                }
              : {},
          )
          .get(`/${MODEL}`)
      ).map(
        (item: TDomain): TDomain => ({
          id: item.id,
          type: item.type === MODEL_ID ? MODEL : MODEL_ID,
          attributes: {
            name: item?.attributes?.name ?? undefined,
            image: item?.attributes?.image ?? undefined,
            content: item?.attributes?.content ?? undefined,
            subline: item?.attributes?.subline ?? undefined,
            title: item?.attributes?.title ?? undefined,
            url: item?.attributes?.url ?? undefined,
          },
        }),
      )

      _fetchState.value = EFetchState.SUCCEEDED
    } catch (error: WretchError | unknown) {
      _fetchState.value = EFetchState.FAILED
      _errors.value = toRequestErrors(error as WretchError)
      // eslint-disable-next-line no-console
      console.error(toRaw(_errors.value))
    }
  }

  onMounted(async () => {
    if (options?.useAutoFetch) {
      await _doFetch()
    }
  })

  onUnmounted(() => {
    _fetchState.value = EFetchState.IDLE
    _domains.value = []
    _errors.value = undefined
  })

  return {
    fetchState: readonly(_fetchState),
    errors: readonly(_errors),
    domains: readonly(_domains),
    fetch: _doFetch,
  }
}
