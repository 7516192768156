<template>
  <header :class="container">
    <nav :class="navigation">
      <RouterLink
        v-if="!props.hideLogo || showAllItems"
        :class="logoContainer"
        :to="{ name: CRouteNames.home }"
      >
        <MainNavigationLogo :classes="{ logo: props.classes.logo }" />
        <span :class="logoClaim" v-html="CNavigationLogoClaim.text" />
      </RouterLink>

      <div :class="growItem"></div>

      <div :class="navigationItemsGroup">
        <router-link
          v-for="item in navigationItems"
          :key="item.path"
          :class="
            navigationItem({ class: [props.classes.navigationItem, item.cssClasses] })
          "
          :to="{ path: item.path, hash: item.hash }"
        >
          {{ item.title }}
        </router-link>

        <router-link
          v-if="
            (currentRoute.name !== CAuthenticationRouteNames.signIn && !isLoggedIn) ||
            showAllItems
          "
          :class="navigationButton"
          :to="{ name: CAuthenticationRouteNames.signIn }"
        >
          <button role="link" :class="navigationButtonText">Sign In</button>
        </router-link>

        <RouterLink
          v-if="isLoggedIn || showAllItems"
          v-slot="{ navigate }"
          :to="{ name: CRouteNames.dashboard }"
          :class="navigationButton"
        >
          <button role="link" :class="navigationButtonText" @click="navigate">
            Dashboard
          </button>
        </RouterLink>
      </div>

      <div v-if="isLoggedIn || showAllItems" :class="signOutItem">
        <button role="link" @click="logout">
          <HeroiconLogoutOutline />
        </button>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
export const componentName = 'MainNavigation'
</script>

<script lang="ts" setup>
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { RouterLink, useRouter } from 'vue-router'
import { onMounted, ref, defineOptions, computed } from 'vue'
import MainNavigationLogo from '@/app/components/MainNavigationLogo.vue'
import { useUserStore } from '@/app/services/useUserStore'
import HeroiconLogoutOutline from '@/app/components/HeroiconLogoutOutline.vue'
import type { TMainNavigationItem } from '@/app/contracts/TMainNavigationItem'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import { CAuthenticationRouteNames } from '@/domain/Authentication'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import { CNavigationLogoClaim } from '@/app/contracts/CNavigationLogoClaim'

defineOptions({
  name: componentName,
})

const { currentRoute } = useRouter()

const props = withDefaults(
  defineProps<{
    classes?: {
      container?: string
      navigation?: string
      logo?: string
      logoContainer?: string
      logoClaim?: string
      growItem?: string
      navigationItemsGroup?: string
      navigationItem?: string
      navigationButton?: string
      navigationButtonText?: string
      signOutItem?: string
    }
    hideLogo?: boolean
  }>(),
  {
    classes: () => ({
      container: undefined,
      navigation: undefined,
      logo: undefined,
      logoContainer: undefined,
      logoClaim: undefined,
      growItem: undefined,
      navigationItemsGroup: undefined,
      navigationItem: undefined,
      navigationButton: undefined,
      navigationButtonText: undefined,
      signOutItem: undefined,
    }),
    hideLogo: false,
  },
)

const container = computed(() =>
  twMerge(
    cva(
      'flex flex-col items-end justify-center font-sans font-medium leading-[150%] w-full min-h-nav max-h-nav px-4 bg-base-100 max-w-7xl',
    )({
      class: [props.classes.container, componentName],
    }),
  ),
)

const navigation = computed(() =>
  twMerge(
    cva('flex justify-between gap-4 w-full')({
      class: [props.classes.navigation],
    }),
  ),
)

const logoContainer = computed(() =>
  twMerge(
    cva('grow-0 flex items-center justify-center gap-1.5')({
      class: [props.classes.logoContainer],
    }),
  ),
)

const logoClaim = computed(() =>
  twMerge(
    cva('hidden sm:block font text-xs text-state-error mt-1')({
      class: [props.classes.logoClaim],
    }),
  ),
)

const growItem = computed(() =>
  twMerge(
    cva('grow')({
      class: [props.classes.growItem],
    }),
  ),
)

const navigationItemsGroup = computed(() =>
  twMerge(
    cva(
      'flex shrink-0 grow-0 items-center justify-center space-x-2 xs:space-x-4 sm:space-x-6 my-auto text-center text-base-500 text-sm',
    )({
      class: [props.classes.growItem],
    }),
  ),
)

const navigationButton = computed(() =>
  twMerge(
    cva(
      'flex grow-0 justify-center font-medium rounded-xl px-4 py-1 bg-state-error text-state-error-content border border-state-error hover:border-state-error-focus hover:bg-state-error-focus text-sm uppercase',
    )({
      class: [props.classes.navigationButton],
    }),
  ),
)

const navigationButtonText = computed(() =>
  twMerge(
    cva('uppercase')({
      class: [props.classes.navigationButtonText],
    }),
  ),
)

const signOutItem = computed(() =>
  twMerge(
    cva(
      'flex grow-0 items-center justify-center -ml-1 py-1 text-state-error hover:text-state-error-focus hover:underline text-sm w-[32px]',
    )({
      class: [props.classes.signOutItem],
    }),
  ),
)

const navigationItem = cva('font-light uppercase')

const showAllItems = ref(false)
const { isLoggedIn, reset } = useUserStore()

const { replace } = useRouter()
const logout = async () => {
  await useAuthentication().logout({
    userCleanup: reset,
    navigate: () => replace({ name: CAuthenticationRouteNames.signIn }),
  })
}

const navigationItems = ref<TMainNavigationItem[]>([])
onMounted(async () => {
  const items: TMainNavigationItem[] =
    (
      (
        await fetchOneEntry({
          apiKey: useConfig().get().builderIO.publicApiKey,
          model: CBuilderModels.MainNavigationItems,
          query: {
            id: '502e04655ba744efbef143d078821682',
          },
        })
      )?.data ?? null
    )?.items ?? []

  navigationItems.value = items.map((item) => ({
    ...item,
    hash: item.hash === 'undefined' ? undefined : item.hash,
  }))
})
</script>

<style scoped></style>
