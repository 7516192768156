import type { RouteRecordRaw } from 'vue-router'
import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'

export const bookRoutes: RouteRecordRaw[] = [
  {
    path: '/books',
    name: CContentRouteNames.books.index,
    component: () => import('@/domain/Content/components/PContent.vue'),
  },
  {
    path: '/books/:slug',
    name: CContentRouteNames.books.show,
    component: () => import('@/domain/Book/components/PBook.vue'),
    props: {
      contentModel: CBuilderModels.BookPage,
    },
  },
]
