<template>
  <RouterLink
    v-if="isVisible"
    :class="container"
    tag="a"
    :to="props.entry.attributes.path"
  >
    <article :id="slug" :class="contentContainer">
      <div :class="header">
        <img
          loading="lazy"
          :src="coverImage"
          :alt="`Teaser image for the '${props.entry.attributes.title}' guide published on Miljn`"
          :class="image"
          width="280"
          height="636"
        />
      </div>
      <section :class="sectionContent">
        <h2 :class="title" v-html="props.entry.attributes.title" />
        <div :class="footer">
          <RouterLink
            v-if="domainLogo && domainComputed?.attributes?.name && domainLogoComputed"
            :class="domainLogoContainer"
            tag="a"
            :to="domainRouteComputed"
          >
            <img
              loading="lazy"
              :class="domainLogo"
              :src="domainLogoComputed"
              :alt="`Domain logo of ${domainComputed?.attributes?.name}`"
            />
          </RouterLink>

          <div :class="categoryLabelContainer">
            <span
              v-for="(category, index) in categoriesComputed"
              :key="category.id"
              :class="[categoryLabel, index > props.maxVisibleCategories - 1 && 'hidden']"
              v-html="category.attributes.label"
            />
          </div>
        </div>
      </section>
    </article>
  </RouterLink>
</template>

<script lang="ts">
export const componentName = 'BookDirectoryCard'
</script>

<script lang="ts" setup>
import { computed, defineOptions, type UnwrapRef } from 'vue'
import { useIsTouchDevice } from '@/app/composables/useIsTouchDevice'
import {
  type NavigationGuardNext,
  onBeforeRouteLeave,
  type RouteLocationNormalized,
  useRouter,
} from 'vue-router'
import slugify from 'slugify'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import type { TBookDirectoryEntry } from '@/domain/Book/contracts/TBookDirectoryEntry'
import type { DeepReadonly } from '@vue/reactivity'
import type { TAuthor } from '@/domain/Book/contracts/TAuthor'
import type { TCategory } from '@/domain/Book/contracts/TCategory'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import { useDocumentOrigin } from '@/domain/documents/composables/useDocumentOrigin'
import { EDocumentInteractions } from '@/domain/documents/contracts/EDocumentInteractions'
import type { TDomain } from '@/domain/domains/contracts/TDomain'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<{
    entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>
    classes?: {
      container?: string
      contentContainer?: string
      header?: string
      image?: string
      imageBadge?: string
      sectionContent?: string
      title?: string
      footer?: string
      domainLogoContainer?: string
      domainLogo?: string
      categoryLabelContainer?: string
      categoryLabel?: string
    }

    imageBadgeText?: string
    maxVisibleAuthors?: number
    maxVisibleCategories?: number

    findAuthors: (entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>) => TAuthor[]
    findCategories: (entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>) => TCategory[]
    findDomain: (
      entry: DeepReadonly<UnwrapRef<TBookDirectoryEntry>>,
    ) => TDomain | undefined
  }>(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      header: undefined,
      image: undefined,
      imageBadge: undefined,
      sectionContent: undefined,
      title: undefined,
      footer: undefined,
      domainLogoContainer: undefined,
      domainLogo: undefined,
      categoryLabelContainer: undefined,
      categoryLabel: undefined,
    }),
    imageBadgeText: 'Curated',
    maxVisibleAuthors: 3,
    maxVisibleCategories: 1,
  },
)

const { currentRoute } = useRouter()

const { isTouchDevice } = useIsTouchDevice()
const isVisible = computed(
  () => props.entry.attributes.title && props.entry.attributes.image,
)
const slug = computed(() =>
  !props.entry.attributes.path ? undefined : slugify(props.entry.attributes.path),
)

const { resolve } = useRouter()

const categoriesComputed = computed(() => props.findCategories(props.entry))
const domainComputed = computed(() => props.findDomain(props.entry))

const coverImage = computed(() =>
  domainComputed.value?.id && props.entry.attributes.caseCover
    ? props.entry.attributes.caseCover
    : props.entry.attributes.image,
)

const domainLogoComputed = computed(() =>
  domainComputed.value?.id ? domainComputed.value?.attributes.image : undefined,
)

const container = computed(() =>
  twMerge(
    cva(
      'flex flex-col text-left cursor-pointer transition-transform duration-300 ease-in-out',
      {
        variants: {
          isMobile: {
            false: 'group',
          },
        },
      },
    )({
      isMobile: isTouchDevice.value,
      class: [props?.classes?.container, componentName],
    }),
  ),
)

const contentContainer = computed(() =>
  twMerge(
    cva('flex flex-col grow gap-2')({
      class: props?.classes?.contentContainer,
    }),
  ),
)

const header = computed(() =>
  twMerge(
    cva(
      'relative overflow-hidden shadow-lg rounded-lg group-hover:scale-110 transition-transform duration-300 ease-in-out w-2/3',
    )({
      class: props?.classes?.header,
    }),
  ),
)

const image = computed(() =>
  twMerge(
    cva('w-full object-cover aspect-[0.705]')({
      class: props?.classes?.image,
    }),
  ),
)

const sectionContent = computed(() =>
  twMerge(
    cva('flex flex-col justify-between items-between px-0 grow py-3 w-full')({
      class: props?.classes?.sectionContent,
    }),
  ),
)

const title = computed(() =>
  twMerge(
    cva('text-lg font-medium text-base-content leading-6 max-w-[92%]')({
      class: props?.classes?.title,
    }),
  ),
)

const footer = computed(() =>
  twMerge(
    cva(
      'flex flex-col justify-between items-start mt-3 text-sm leading-5 text-base-400 gap-2',
    )({
      class: props?.classes?.footer,
    }),
  ),
)

const domainLogoContainer = computed(() =>
  twMerge(
    cva('flex items-center justify-start')({
      class: props?.classes?.domainLogoContainer,
    }),
  ),
)

const domainLogo = computed(() =>
  twMerge(
    cva('h-[24px] transition-transform duration-300 ease-in-out hover:scale-105', {
      variants: {
        isMobile: {
          false: 'hover:scale-105',
        },
      },
    })({
      class: props?.classes?.domainLogo,
    }),
  ),
)

const categoryLabelContainer = computed(() =>
  twMerge(
    cva('flex flex-row overflow-hidden gap-2')({
      class: props?.classes?.categoryLabelContainer,
    }),
  ),
)

const categoryLabel = computed(() =>
  twMerge(
    cva(
      'inline-block font-normal text-base shrink overflow-hidden overflow-ellipsis whitespace-nowrap',
    )({
      class: props?.classes?.categoryLabel,
    }),
  ),
)

const domainRouteComputed = computed(() => {
  if (domainComputed.value?.attributes.url) {
    return resolve(domainComputed.value?.attributes.url)
  }

  return { path: '/' }
})

onBeforeRouteLeave(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (to.path === props.entry.attributes.path) {
      const properties = await useDocumentOrigin({ router: useRouter() }).fromSnapshot(
        {
          interaction_type: EDocumentInteractions.showedInterestInADocument,
          route: currentRoute.value.name,
          route_query: { ...currentRoute.value.query },
          route_params: { ...currentRoute.value.params },
        },
        props.entry?.attributes?.template,
      )

      captureWithUser(EUserEvents.interactedWithADocument, properties)
    }
    next()
    return
  },
)
</script>

<style scoped></style>
