import type { RouteRecordRaw } from 'vue-router'
import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'

export const domainRoutes: RouteRecordRaw[] = [
  {
    path: '/domains/:slug',
    name: CContentRouteNames.domains.show,
    component: () => import('@/domain/domains/components/PDomain.vue'),
    props: {
      contentModel: CBuilderModels.DomainPage,
    },
  },
] satisfies RouteRecordRaw[]
