<template>
  <component :is="props.tag" :class="container" role="article">
    <div v-if="entry.attributes.image" :class="imageContainer">
      <img
        loading="lazy"
        :src="props.entry.attributes.image"
        :alt="`${props.entry.attributes.title} cover`"
        :class="image"
      />
    </div>
    <div class="flex flex-col mt-2 w-full max-md:max-w-full">
      <div class="flex flex-col w-full">
        <TextHeading
          :title-tag="EHeadline.H2"
          :content="{
            title: props.entry.attributes.title,
          }"
          :classes="{
            container: titleContainer,
            title: title,
          }"
        />

        <div
          v-if="(props.entry.attributes.specs ?? []).length > 0"
          class="flex flex-col gap-2 lg:gap-3 py-2 mt-2"
        >
          <ul
            class="flex flex-row flex-wrap justify-start xs:justify-between md:justify-start gap-4 lg:gap-8"
          >
            <SpecItem
              v-for="(spec, index) in props.entry.attributes.specs"
              :key="index"
              tag="li"
              :type="toSpecType(spec.type)"
              :value="spec.value"
              container-classes="min-w-[30vw] xs:min-w-0 md:min-w-[16vw] lg:min-w-0"
            />
          </ul>
        </div>
      </div>

      <div
        v-if="!isEmpty(props.entry.attributes.description)"
        :class="content"
        v-html="props.entry.attributes.description"
      />

      <SignInWithEmailForm
        :usage-context="ESignInWithMagicUriFormUsageContext.STAND_ALONE"
        :action-target="props.entry.attributes.template"
        action-title="View Guide"
      />
    </div>
  </component>
</template>

<script lang="ts">
export const componentName = 'CaseInformation'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import type { TCaseEntry } from '@/domain/domains/contracts/TCaseEntry'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import SpecItem from '@/domain/Content/components/SpecItem.vue'
import { ESpecTypes } from '@/domain/Content/contracts/ESpecTypes'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { ESignInWithMagicUriFormUsageContext } from '@/domain/Authentication/contracts/ESignInWithMagicUriFormUsageContext'
import SignInWithEmailForm from '@/domain/Authentication/components/SignInWithEmailForm.vue'
import { isEmpty } from 'lodash-es'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<{
    classes?: {
      container?: string
      titleContainer?: string
      title?: string
      imageContainer?: string
      image?: string
      content?: string
    }
    tag?: 'div' | 'li'
    entry: TCaseEntry
  }>(),
  {
    tag: 'div',
    classes: () => ({
      container: undefined,
      titleContainer: undefined,
      title: undefined,
      imageContainer: undefined,
      image: undefined,
      content: undefined,
    }),
  },
)

const container = computed(() =>
  twMerge(
    cva('flex flex-col sm:flex-row sm:gap-6')({
      class: [props.classes?.container, componentName],
    }),
  ),
)

const titleContainer = computed(() =>
  twMerge(
    cva('w-full flex flex-col justify-between items-start')({
      class: props.classes.titleContainer,
    }),
  ),
)

const title = computed(() =>
  twMerge(cva('text-2xl !mt-2 max-w-[720px]')({ class: props.classes.title })),
)

const imageContainer = computed(() =>
  twMerge(
    cva(
      'flex overflow-hidden flex-col self-center max-w-full rounded-md w-[250px] max-sm:w-full max-sm:max-w-[250px]',
    )({
      class: props.classes?.imageContainer,
    }),
  ),
)

const image = computed(() =>
  twMerge(
    cva('object-contain w-full aspect-[0.75]')({
      class: props.classes?.image,
    }),
  ),
)

const content = computed(() =>
  twMerge(
    cva('w-full py-2 text-base text-muted-foreground prose mt-2 max-w-[100%]')({
      class: props.classes?.content,
    }),
  ),
)

const toSpecType = (value: string) => {
  return ESpecTypes[value.toUpperCase() as keyof typeof ESpecTypes]
}
</script>

<style scoped lang="postcss"></style>
