<template>
  <FlexibleSectionLayout
    v-if="titleComputed"
    tag="section"
    :visualize-width="false"
    width="xNarrow"
    :class="componentName"
    :container-classes="containerClasses"
    :content-container-classes="contentContainer"
  >
    <div class="flex flex-col w-full pt-8">
      <div v-if="imageComputed" :class="imageContainer">
        <img
          loading="lazy"
          :src="imageComputed"
          :alt="`${nameComputed} logo`"
          :class="image"
        />
      </div>
      <TextHeading
        :title-tag="EHeadline.H1"
        :content="{
          title: titleComputed,
          subline: sublineComputed,
        }"
        :classes="{
          container: titleContainer,
          title: title,
          subline: subline,
        }"
      />
    </div>

    <div v-if="referencesComputed.length >= 1" :class="referencesContainer">
      <a
        v-for="reference in referencesComputed"
        :key="reference.url"
        :href="reference.url"
        :class="referenceIconContainer"
        :title="reference.name"
        target="_blank"
      >
        <SocialIcons :size="referenceIcon" :type="reference.type" />
      </a>
    </div>

    <div :class="content" v-html="contentComputed" />
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'CaseIntroSection'
</script>

<script lang="ts" setup>
import { computed, type ComputedRef, defineOptions } from 'vue'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import { cva } from 'class-variance-authority'
import { useOnePageEntryWithOptions } from '@/app/composables/useOnePageEntry'
import type { TUsePageContentProps } from '@/domain/Book/contracts/TUsePageContentProps'
import { Environment } from '@/app/support/Environment'
import { twMerge } from 'tailwind-merge'
import SocialIcons from '@/app/components/SocialIcons.vue'
import type { TAuthorReferenceModel } from '@/domain/Book/contracts/TAuthorReferenceModel'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import TextHeading from '@/domain/Content/components/TextHeading.vue'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      classes?: {
        container?: string
        contentContainer?: string
        imageContainer?: string
        image?: string
        titleContainer?: string
        title?: string
        subline?: string
        referencesContainer?: string
        referenceIconContainer?: string
        referenceIcon?: string
        content?: string
      }
      title?: string
      name?: string
      subline?: string
      image?: string
      content?: string
      references?: TAuthorReferenceModel[]
    } & TUsePageContentProps & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes?: any
      }
  >(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      imageContainer: undefined,
      image: undefined,

      titleContainer: undefined,
      title: undefined,
      subline: undefined,

      referencesContainer: undefined,
      referenceIconContainer: undefined,
      referenceIcon: undefined,
      content: undefined,
    }),
    usePageContent: false,
    pageContentModel: 'page',
    references: () => [],
  },
)

const containerClasses = computed(() =>
  twMerge(cva('w-full my-6')({ class: props.classes.container })),
)

const contentContainer = computed(() =>
  twMerge(cva('flex-col')({ class: props.classes.contentContainer })),
)

const imageContainer = computed(() =>
  twMerge(
    cva('w-full flex flex-col justify-between items-start')({
      class: props.classes.imageContainer,
    }),
  ),
)

const image = computed(() =>
  twMerge(
    cva('object-contain max-h-[64px]')({
      class: props.classes.image,
    }),
  ),
)

const titleContainer = computed(() =>
  twMerge(
    cva('w-full flex flex-col justify-between items-start')({
      class: props.classes.titleContainer,
    }),
  ),
)

const title = computed(() =>
  twMerge(cva('text-2xl !mt-2')({ class: props.classes.title })),
)

const subline = computed(() =>
  twMerge(
    cva('flex flex-row gap-2 items-center text-base text-base-500 font-medium')({
      class: props.classes.title,
    }),
  ),
)

const referencesContainer = computed(() =>
  twMerge(
    cva('flex items-start gap-2 mt-4')({
      class: props.classes?.referencesContainer,
    }),
  ),
)

const referenceIconContainer = computed(() =>
  twMerge(
    cva(
      'hover:scale-125 transition-transform duration-300 ease-in-out border border-accent-disabled rounded-lg p-1',
    )({
      class: props.classes.referenceIconContainer,
    }),
  ),
)

const referenceIcon = computed(() =>
  twMerge(cva('w-5 h-5')({ class: props.classes?.referenceIcon })),
)

const content = computed(() =>
  twMerge(
    cva('w-full py-2 text-base text-muted-foreground prose mt-4 max-w-[100%]')({
      class: props.classes?.content,
    }),
  ),
)

const { content: page, doFetch } = useOnePageEntryWithOptions(props.pageContentModel, {
  useAutoFetch: false,
  useEnvironment: Environment.current(),
})

if (props.usePageContent) {
  doFetch({})
}

const titleComputed = computed(() =>
  props.usePageContent ? page.value?.data?.title : props.title,
)
const sublineComputed = computed(() =>
  props.usePageContent ? page.value?.data?.subline : props.subline,
)
const imageComputed = computed(() =>
  props.usePageContent ? page.value?.data?.image : props.image,
)
const nameComputed = computed(() =>
  props.usePageContent ? page.value?.data?.name : props.name,
)
const contentComputed = computed(() =>
  props.usePageContent ? page.value?.data?.content : props.content,
)

const referencesComputed: ComputedRef<TAuthorReferenceModel[]> = computed(() => {
  if (props.usePageContent) {
    return page.value?.data?.references ?? []
  }

  return props.references ? props.references : []
})
</script>

<style scoped></style>
