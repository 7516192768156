import { SectionLayoutComponentRegistration } from '@/domain/Content/components/SectionLayoutComponentRegistration'
import { TitleWithSublineRegistration } from '@/domain/Content/components/TitleWithSublineComponentRegistration'
import { ContentCanvasRegistration } from '@/domain/Content/components/ContentCanvasRegistration'
import { PricingTableCardRegistration } from '@/domain/Content/components/PricingTableCardRegistration'
import { PricingTableSectionRegistration } from '@/domain/Content/components/PricingTableSectionRegistration'
import { ScrollTargetRegistration } from '@/domain/prompt/components/ScrollTargetRegistration'
import { PromptInputRegistration } from '@/domain/prompt/components/PromptInputRegistration'
import { TeamSectionRegistration } from '@/domain/Content/components/TeamSectionRegistration'
import { VideoSectionRegistration } from '@/domain/Content/components/VideoSectionRegistration'
import { FaqSectionRegistration } from '@/domain/Content/components/FaqSectionRegistration'
import { IFrameBasedVideoPlayerComponentRegistrations } from '@/domain/Content/components/IFrameBasedVideoPlayerComponentRegistrations'
import { ActionableTextHeroComponentRegistration } from '@/domain/Content/components/ActionableTextHeroComponentRegistration'
import { CallToActionButtonComponentRegistration } from '@/domain/Content/components/CallToActionButtonComponentRegistration'
import { StepIconComponentRegistration } from '@/domain/Content/components/StepIconComponentRegistration'
import { CaseIntroSectionComponentRegistration } from '@/domain/Content/components/CaseIntroSectionComponentRegistration'
import { CaseContentSectionComponentRegistration } from '@/domain/Content/components/CaseContentSectionComponentRegistration'
import { CaseMethodologySectionComponentRegistration } from '@/domain/Content/components/CaseMethodologySectionComponentRegistration'
import { CaseTestimonialsSectionComponentRegistration } from '@/domain/Content/components/CaseTestimonialsSectionComponentRegistration'
import { CaseSourceAuthorSectionComponentRegistration } from '@/domain/Content/components/CaseSourceAuthorSectionComponentRegistration'
import { DirectorySectionRegistration } from '@/domain/directory/components/DirectorySectionRegistration'
import { SignInWithEmailFormComponentRegistration } from '@/domain/Authentication/components/SignInWithEmailFormComponentRegistration'
import { TextHeadingComponentRegistration } from '@/domain/Content/components/TextHeadingComponentRegistration'
import { FlexibleSectionLayoutComponentRegistration } from '@/domain/Content/components/FlexibleSectionLayoutComponentRegistration'
import { ComparisonTableSectionComponentRegistration } from '@/domain/Content/components/ComparisonTableSectionComponentRegistration'
import { SignInWithMagicUriFormComponentRegistration } from '@/domain/Authentication/components/SignInWithMagicUriFormComponentRegistration'
import { AuthorSectionComponentRegistration } from '@/domain/Content/components/AuthorSectionComponentRegistration'
import { BooksDirectoryComponentRegistration } from '@/domain/Book/components/BooksDirectoryComponentRegistration'
import { DomainHeroSectionComponentRegistration } from '@/domain/Content/components/DomainHeroSectionComponentRegistration'
import { DomainSectionComponentRegistration } from '@/domain/Content/components/DomainSectionComponentRegistration'

export const baseComponentsRegistrationsForBuilderIo = (isEditing: boolean) => {
  return [
    ActionableTextHeroComponentRegistration(isEditing),
    AuthorSectionComponentRegistration(isEditing),
    BooksDirectoryComponentRegistration(isEditing),
    CallToActionButtonComponentRegistration(isEditing),
    CaseContentSectionComponentRegistration(isEditing),
    CaseIntroSectionComponentRegistration(isEditing),
    CaseMethodologySectionComponentRegistration(isEditing),
    CaseSourceAuthorSectionComponentRegistration(isEditing),
    CaseTestimonialsSectionComponentRegistration(isEditing),
    ComparisonTableSectionComponentRegistration(isEditing),
    ContentCanvasRegistration(isEditing),
    DirectorySectionRegistration(isEditing),
    DomainHeroSectionComponentRegistration(isEditing),
    DomainSectionComponentRegistration(isEditing),
    FaqSectionRegistration(isEditing),
    FlexibleSectionLayoutComponentRegistration(isEditing),
    IFrameBasedVideoPlayerComponentRegistrations(isEditing),
    PricingTableCardRegistration(isEditing),
    PricingTableSectionRegistration(isEditing),
    PromptInputRegistration(isEditing),
    ScrollTargetRegistration(isEditing),
    SectionLayoutComponentRegistration(isEditing),
    SignInWithEmailFormComponentRegistration(isEditing),
    SignInWithMagicUriFormComponentRegistration(isEditing),
    StepIconComponentRegistration(isEditing),
    TeamSectionRegistration(isEditing),
    TextHeadingComponentRegistration(isEditing),
    TitleWithSublineRegistration(isEditing),
    VideoSectionRegistration(isEditing),
  ]
}
