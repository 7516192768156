<template>
  <FlexibleSectionLayout
    v-if="casesComputed.length > 0"
    tag="section"
    :visualize-width="false"
    width="xNarrow"
    :class="componentName"
    :container-classes="containerClasses"
    :content-container-classes="contentContainer"
  >
    <ul v-if="casesComputed.length > 0" :class="casesContainer">
      <CaseInformation
        tag="li"
        v-for="(entry, index) in casesComputed"
        :key="entry?.id ?? index"
        :entry="entry"
        :classes="{}"
      />
    </ul>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'DomainSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import { twMerge } from 'tailwind-merge'
import { useOnePageEntryWithOptions } from '@/app/composables/useOnePageEntry'
import type { TUsePageContentProps } from '@/domain/Book/contracts/TUsePageContentProps'
import { Environment } from '@/app/support/Environment'
import { toCaseEntry } from '@/domain/domains/support/toCaseEntry'
import { TCaseEntry } from '@/domain/domains/contracts/TCaseEntry'
import CaseInformation from '@/domain/domains/components/CaseInformation.vue'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      classes?: {
        container?: string
        contentContainer?: string
        authorsContainer?: string
        authorName?: string
        avatar?: string
        referenceIcon?: string
        role?: string
        description?: string
      }
      cases?: any[]
    } & TUsePageContentProps & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes?: any
      }
  >(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      titleContainer: undefined,
      authorsContainer: undefined,
      authorName: undefined,
      avatar: undefined,
      referenceIcon: undefined,
      role: undefined,
      description: undefined,
    }),
    usePageContent: false,
    pageContentModel: 'page',
    cases: () => [],
  },
)

const containerClasses = computed(() =>
  twMerge(cva('w-full my-6')({ class: props.classes.container })),
)

const contentContainer = computed(() =>
  twMerge(cva('flex-col')({ class: props.classes.contentContainer })),
)

const casesContainer = computed(() =>
  twMerge(cva('flex flex-col gap-8')({ class: props.classes.contentContainer })),
)

const { content: page, doFetch } = useOnePageEntryWithOptions(props.pageContentModel, {
  useAutoFetch: false,
  useEnvironment: Environment.current(),
})

if (props.usePageContent) {
  doFetch({})
}

const casesComputed = computed(() => {
  if (props.usePageContent) {
    return (page.value?.data?.cases ?? [])
      .map(toCaseEntry)
      .filter((caseEntry: TCaseEntry | undefined) => caseEntry)
  }

  return []
})
</script>

<style scoped></style>
