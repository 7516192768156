import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/DomainSection.vue'
import { CDomainModel } from '@/domain/domains/contracts/CDomainModel'

export const DomainSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'DomainSection',
  friendlyName: 'DomainSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      friendlyName: 'Use page content',
      name: 'usePageContent',
      type: 'boolean',
      helperText: 'Derive content from enclosing page instead of manually adding.',
      defaultValue: false,
    },
    {
      friendlyName: 'Define page content model',
      name: 'pageContentModel',
      type: 'string',
      enum: ['page', CDomainModel.NAME],
      defaultValue: CDomainModel.NAME,
      showIf(options) {
        return options.get('usePageContent') === true
      },
    },
    {
      name: 'classes',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'container',
          type: 'text',
          defaultValue: 'mt-12 mb-6',
        },
        {
          name: 'contentContainer',
          type: 'text',
        },
        {
          name: 'titleContainer',
          type: 'text',
        },
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'subline',
          type: 'text',
        },
        {
          name: 'divider',
          type: 'text',
        },
        {
          name: 'authorsContainer',
          type: 'text',
        },
        {
          name: 'authorName',
          type: 'text',
        },
        {
          name: 'avatar',
          type: 'text',
        },
        {
          name: 'referenceIcon',
          type: 'text',
        },
        {
          name: 'role',
          type: 'text',
        },
        {
          name: 'description',
          type: 'text',
        },
      ],
    },
    {
      name: 'cases',
      type: 'list',
      copyOnAdd: false,
      showIf(options) {
        return options.get('usePageContent') === false
      },
      subFields: [
        {
          name: 'case',
          type: 'reference',
          model: 'book-page',
          // showIf(options) {
          //   return options.get('usePageContent') !== true
          // },
        },
      ],
    },
  ],
})
